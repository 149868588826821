<template>
  <div class="ferrari_media">
    <AnimatedComponent :duration="450" animationType="fade" class="ferrari_media_cover">
      <div  class="ferrari_media_contain outer">
        <AnimatedComponent :duration="1050" animationType="slide-fade" class="ferrari_media_det inner">
          <h4>01</h4>
          <p>
            1YR/12K WARRANTY <br />
            PARTS AND LABOR
          </p>
        </AnimatedComponent>
        <AnimatedComponent :duration="1050" animationType="slide-fade" class="ferrari_media_det inner">
          <h4>02</h4>
          <p>
            FREE SHUTTLE <br />
            SERVICE.
          </p>
        </AnimatedComponent>
        <AnimatedComponent :duration="1050" animationType="slide-fade" class="ferrari_media_det inner">
          <h4>03</h4>
          <p>
            24H TOWING <br />
            SERVICE
          </p>
        </AnimatedComponent>
      </div>
    </AnimatedComponent>
  </div>
</template>

<script>
import AnimatedComponent from '../AnimatedComponent.vue';

export default {
  name: "Ferrari",
  components: {AnimatedComponent},
};
</script>

<style scoped>
/* ferrari media */
.ferrari_media {
  max-width: 1480px;
  margin: 0px auto;
  position: relative;
  padding: 20px 20px;
}

.ferrari_media_contain {
  position: absolute;
  width: 90%;
  height: 450px;
  max-width: 0px auto;
  /* background-image: url("../assets/cosmin21.jpg"); */
  background-image: url("../../assets/images/cosmin21.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  transform: translateY(-70%) translateX(3%);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 200px;
}

.ferrari_media_det {
  text-align: left;
  padding: 10px 60px;
  color: #000;
  font-weight: 700;
  color: white;
}

.ferrari_media_det h4 {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 0px;
}

@media screen and (max-width: 800px) {
  .ferrari_media_contain {
    flex-direction: column;
    padding-top: 50px;
    max-width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .ferrari_media_contain {
    max-width: 80%;
    transform: translateY(-50%) translateX(8%);
    display: flex;
    background-position: unset;
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .ferrari_media_contain {
    background-position: center;
    padding: 30px 20px;
    transform: translateY(-50%) translateX(14%);
    height: fit-content;
    align-items: start;
  }

  .ferrari_media_det h4 {
    margin-top: 0px;
  }
}

@media screen and (max-width: 400px) {
  .ferrari_media_contain {
    max-width: 70%;
    height: fit-content;
    transform: translateY(-50%) translateX(20%);
    background-position: center;
  }
}

  

</style>
