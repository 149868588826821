<template>
  <div>
    <head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=no"
      />
      <title>{{ $route.name }}</title>
    </head>

    <main>
      <v-app>
        <v-main>
          <Navbar />
          <router-view></router-view>
        </v-main>
      </v-app>
    </main>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

import image from "@/assets/images/car-logo.png";

export default {
  name: "AppLayout",
  components: { Navbar },

  // Add any necessary logic or lifecycle hooks for the layout component
};
</script>

<style>
/* Add your layout styles here */
</style>
