import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

/* scrool animation */
// import scrollAnimation from "./directives/scrollAnimation";
import scrollanimation from "./directives/scrollanimation";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
/* import specific icons */
import {
  faUserSecret,
  faPlus,
  faArrowRight,
  faStar,
  faQuoteLeft,
  faUserCog,
  faCarAlt,
  faCircleCheck,
  faPhone,
  faCarRear,
  faCircleExclamation,
  faLocation,
  faLocationPinLock,
  faLocationPin,
  faMapLocation,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faYelp,
} from "@fortawesome/free-brands-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "vue3-carousel/dist/carousel.css";
/* add icons to the library */
library.add(
  faClose,
  faMapLocation,
  faCircleExclamation,
  faCalendar,
  faCarRear,
  faUserCog,
  faCarAlt,
  faCircleCheck,
  faUserSecret,
  faPhone,
  faPlus,
  faArrowRight,
  faStar,
  faQuoteLeft,
  faFacebook,
  faTwitter,
  faYelp
);
const VueTelInputOptions = {
  mode: "international",
  onlyCountries: ["GB"],
  inputOptions: { required: true },
  autoFormat: true,
};
import vuetify from "./plugins/vuetify";

import AnimatedComponent from "@/components/AnimatedComponent";

// createApp(App)
//   .component("font-awesome-icon", FontAwesomeIcon)
//   .component("VueDatePicker", VueDatePicker)
//   .component("AnimatedComponent", AnimatedComponent)
//   .use(vuetify)
//   .use(VueToast, {
//     position: "top-right",
//     duration: 4000,
//   })
//   .use(router)
//   .use(VueTelInput, VueTelInputOptions)
//   .mount("#app");


const app = createApp(App);

app
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("VueDatePicker", VueDatePicker)
  .component("AnimatedComponent", AnimatedComponent)
  .use(vuetify)
  .use(VueToast, {
    position: "top-right",
    duration: 4000,
  })
  .use(router)
  .use(VueTelInput, VueTelInputOptions);

// Register the scroll animation directive globally
app.directive("scrollanimation", scrollanimation);

app.mount("#app");