<template>
  <div class="home_section2">
    <div class="section2_top">
      <AnimatedComponent animationType="slide-fade" class="section2_left" v-scrollanimation v-motion="{ variants: spanAni }">
        <h2>
          Experience Automotive Excellence: Your Search Ends at Cosmin Paint &
          Body
        </h2>
        <p>
          Welcome to Csm Paint & Body Ltd, your trusted automotive partner in
          Newcastle, Staffordshire. With our team of skilled professionals and a
          decade of experience, we are committed to providing top-notch services
          to keep your vehicle in pristine condition. From MOTs and accident
          repairs to paintwork restoration and professional polishes, we offer a
          comprehensive range of solutions tailored to meet your specific needs.
          Our attention to detail, use of advanced techniques, and commitment to
          customer satisfaction sets us apart. Experience the difference at
          Csm Paint & Body Ltd, where we combine expertise with passion to
          deliver excellence to every customer, every time.
        </p>

        <div class="section2_left_bottom">
          <router-link to="/about">MORE ABOUT US</router-link>
        </div>
      </AnimatedComponent>
      <AnimatedComponent animationType="slide-up" class="section2_right">
        <h5>WE ARE CERTIFICATED:</h5>
        <AnimatedComponent animationType="bounce" class="cert_logo">
          <div class="cert_cover">
            <img
              :src="require('@/assets/images/warrant.png')"
              alt="logo of certificate"
              class="logo"
            />
          </div>
        </AnimatedComponent>
      </AnimatedComponent>
    </div>
  </div>
</template>

<script>
import AnimatedComponent from '../AnimatedComponent.vue';
export default {
  components: {
    AnimatedComponent, // Register the component
  },
};
</script>

<style scoped>
.home_section2 {
  /* background-color: #32dbc9; */
  /* background-color: #1d3557; */
  /* background-color: #80ffdb; */
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: rgb(240, 255, 255);
  /* color: white; */
  color: #011627;
  padding-top: 30px;
  padding-bottom: 10px;
}

.section2_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1480px;
  margin: 0px auto;
  padding: 0px 30px;
  gap: 100px;
  padding-bottom: 360px;
}

.section2_left {
  flex: 1;
  text-align: left;
}

.section2_left h2 {
  font-size: 45px;
}

.section2_left p {
  /* color: #ffffff; */
  color: #011627;
}

.section2_left_bottom {
  font-size: 16px;
  letter-spacing: 2px;
  border-bottom: 2px solid rgb(255, 255, 255);
  width: fit-content;
}

.section2_right {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.section2_right h5 {
  letter-spacing: 4px;
}

.cert_logo {
  /* display: grid; */
}

.cert_cover {
  /* border: 1px solid white; */
  padding: 10px 15px;
  border-radius: 50%;
}

.logo {
  width: 400px;
  height: 400px;
}

@media screen and (max-width: 1020px) {
  .section2_left p {
    font-size: 17px;
    color: #011627;
  }
  .section2_left {
    font-size: 20px;
  }
  .section2_right {
    width: 100%;
    gap: 10px;
  }

  .section2_top {
    flex-direction: column;
    align-self: start;
  }

  .cert_logo {
    justify-content: center;
    display: flex;
  }
}

@media screen and (max-width: 750px) {
  .section2_left p {
    font-size: 17px;
   color: #011627;
  }
  .section2_left h2 {
    font-size: 35px;
  }

  .section2_left div {
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 600px) {
  .section2_top {
    padding-bottom: 300px;
  }

  .cert_cover {
  }

  .cert_cover img {
    /* width: 40px; */
  }
}
</style>
