import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

import AppLayout from "../views/layout/AppLayout.vue";

const routes = [
  {
    path: "/",
    name: "HomeLayout",
    // redirect: "",
    component: AppLayout,
    children: [
      {
        path: "/",
        name: "home",
        meta: {
          title: "Welcome To CMS Paint & Body",
        },
        component: HomeView,
      },
      {
        path: "/about",
        name: "about",
        meta: {
          title: "About Us",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },

      {
        path: "/services",
        name: "services",
        meta: {
          title: "Our Services",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ServicesView.vue"),
      },

      {
        path: "/contact",
        name: "contact",
        meta: {
          title: "Contact Us",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
      },

      {
        path: "/appointment",
        name: "appointment",
        meta: {
          title: "Come Book An appiontment",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/AppointmentView.vue"
          ),
      },

      {
        path: "/gallery",
        name: "gallery",
        meta: {
          title: "Some of our work",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/GalleryView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 });
    });
  },
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title;
  }
  // Continue resolving the route
  next();
});

export default router;
