<template>
  <div id="app">
    <div class="home_section3">
      <div class="section3_contain">
        <AnimatedComponent :duration="450" animationType="slide-fade">
          <h2>OUR SERVICES</h2>
        </AnimatedComponent>
        <br />
        <AnimatedComponent :duration="850" animationType="slide-fade">
          <p>VIEW ALL SERVICES</p>
        </AnimatedComponent>
        <div class="section3_grid">
          <!-- <AnimatedComponent :duration="450" animationType="fade"> -->
            <div class="grid_contain">
              <div class="grid_con con1">
                <div class="grid_inner">
                  <router-link to="/appointment" class="plus_cover">
                    <font-awesome-icon
                      icon="fa-solid fa-plus"
                      :style="{ color: 'white' }"
                    />
                  </router-link>
                  <h4>
                    ANNUAL CHECKUPS FROM YOUR PHYSICIAN KEEP YOU IN GOOD HEALTH
                  </h4>
                </div>
              </div>
              <p>Recovery</p>
            </div>
          <!-- </AnimatedComponent> -->
          <!-- <AnimatedComponent :duration="450" animationType="fade"> -->
            <div class="grid_contain">
              <div class="grid_con con2">
                <div class="grid_inner">
                  <router-link to="/appointment" class="plus_cover">
                    <font-awesome-icon
                      icon="fa-solid fa-plus white"
                      :style="{ color: 'white' }"
                    />
                  </router-link>
                  <h4>
                    OUR TECHNICIANS ARE EXPERTS IN PROVIDING HIGH-QUALITY TIRE
                    SERVICES
                  </h4>
                </div>
              </div>
              <p>MOT</p>
            </div>
          <!-- </AnimatedComponent> -->
          <!-- <AnimatedComponent :duration="450" animationType="fade"> -->
            <div class="grid_contain">
              <div class="grid_con con3">
                <div class="grid_inner">
                  <router-link to="/appointment" class="plus_cover">
                    <font-awesome-icon
                      icon="fa-solid fa-plus"
                      :style="{ color: 'white' }"
                    />
                  </router-link>
                  <h4>
                    WE USE STATE-OF-THE-ART SCAN TOOL EQUIPMENT TO PERFORM ENGINE
                    DIAGNOSTICS
                  </h4>
                </div>
              </div>
              <p>Accident repairs</p>
            </div>
          <!-- </AnimatedComponent> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedComponent from '../AnimatedComponent.vue';

export default {
  name: "App",
  components: {AnimatedComponent},
};
</script>

<style scoped>
.section3_contain {
  max-width: 1480px;
  margin: 0px auto;
  padding-top: 200px;
  padding-bottom: 150px;
  padding-left: 30px;
  padding-right: 30px;
}

.section3_contain h2 {
  margin-bottom: 0px;
  text-align: left;
  font-size: 45px;
}

.section3_contain p {
  text-align: left;
  width: fit-content;
  border-bottom: 1px solid #171717;
}

.section3_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
}

.grid_con {
  /* display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 100px; */
  height: 100%;
}

.grid_inner {
  opacity: 0;
  transition: 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 100%;
  /* padding: 50px 20px; */
}

.grid_con:hover .grid_inner {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.451);
}

.con1 {
  background-image: url("../../assets/images/csm7.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* padding: 50px 20px; */
}
.con2 {
  background-image: url("../../assets/images/csmtwocars.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* padding: 50px 20px; */
}
.con3 {
  background-image: url("../../assets/images/csm5.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* padding: 50px 20px; */
}

.plus_cover {
  background-color: red;
  width: fit-content;
  padding: 20px;
  font-size: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 100px;
}

.grid_contain h4 {
  color: white;
  text-align: left;
  font-weight: 600;
  font-size: 23px;
  max-width: 90%;
  margin: 0px auto;
  padding: 50px 20px;
}

.grid_contain p {
  font-weight: 600;
  font-size: 20px;
  text-decoration: none;
  border-bottom: none;
  letter-spacing: 3px;
  text-transform: uppercase;
}

@media screen and (max-width: 1000px) {
  .grid_con {
    height: 80%;
  }

  /* .grid_contain{
          margin-top: 80px;
      } */

  .grid_contain h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 730px) {
  .section3_grid {
    display: flex;
    flex-wrap: wrap;
  }

  .section3_contain {
    padding-top: 220px;
    padding-bottom: 50px;
  }
  .section3_contain h2 {
    font-size: 40px;
  }
}

/* @media screen and (max-width: 1020px) {
          .section3_grid{
              grid-template-columns: 1fr;
          }
      } */
</style>
