<template>
  <div class="navbar">
    <div class="navbar_contain">
      <router-link to="/">
        <img
          style="width: 220px"
          :src="require('../assets/images/car-logo.png')"
          alt="image"
          class="logo"
        />
      </router-link>
      <div class="nav_list">
        <ul>
          <router-link to="/" class="nav_item"><li>Home</li></router-link>
          <router-link to="/about" class="nav_item"><li>About</li></router-link>
          <router-link to="/services" class="nav_item"
            ><li>Services</li></router-link
          >
          <router-link to="/contact" class="nav_item"
            ><li>Contact Us</li></router-link
          >

          <router-link to="/appointment" class="nav_item"
            ><li>Book Now</li></router-link
          >
        </ul>
      </div>
      <!-- hamburger menu -->
      <div class="nav_media">
        <div class="nav_media_det">
          <div id="hamburger" @click="toggle = !toggle">
            <div :id="[toggle ? 'ones' : 'one']"></div>
            <div :id="[toggle ? 'twos' : 'two']"></div>
            <div :id="[toggle ? 'threes' : 'three']"></div>
          </div>
        </div>
      </div>
      <div class="media_cover">
        <div
          :class="[toggle ? 'nav_media_show' : 'nav_media_hide', 'background1']"
        >
          <ul class="list_contain">
            <li @click="goTo('home')">Home</li>
            <li @click="goTo('about')">About</li>
            <li @click="goTo('services')">Services</li>
            <li @click="goTo('contact')">Contact</li>
            <li @click="goTo('appointment')">Book Now</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: false,
    };
  },

  methods: {
    goTo(name) {
      this.toggle = false;
      this.$router.push({ name });
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>

<style scoped>
.navbar {
  padding: 10px 20px;
  text-transform: capitalize;
  /* position: sticky; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* width: inherit; */
  background-color: #232946;
  /* background-color: #d4d8f0; */
  /* background-color: rgb(50, 219, 201); */
  background-color: rgb(240, 255, 255);
  /* background-color: black; */
  margin: 0px auto;
  z-index: 10;
  box-shadow: 0 1px 14px #290223;
}

.navbar_contain {
  max-width: 1480px;
  margin: 0px auto;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar_contain a {
  max-width: 140px;
  display: flex;
  align-items: center;
}

.list_contain a {
  margin-left: 30px;
}

.navbar_contain img {
  width: 100%;
}

.logo {
  height: 20%;
  width: 20%;
}

.nav_list ul {
  display: flex;
  gap: 30px;
}

.nav_list ul li {
  list-style: none;
  outline: none;
  font-weight: 500;
  font-size: 18px;
  transition: 0.5s ease;
  border-bottom: 3px solid transparent;
  color: #232946;
}

.nav_list ul li:hover {
  border-bottom: 3px solid #011c18;
  /* border-bottom: 3px solid #eebbc3; */
}

.nav_list ul a li:active {
  border-bottom: 3px solid #012b25;
  /* border-bottom: 3px solid #f0c2c9; */
}

/* media Query */
.nav_media {
  display: none;
}

.nav_media_det {
  display: flex;
  align-items: center;
  gap: 8px;
}

#hamburger {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

#one,
#two,
#three {
  width: 25px;
  height: 3px;
  border: 30px;
  background-color: rgb(224, 15, 15);
  transition: 0.4s ease-in;
}

#ones,
#twos,
#threes {
  width: 25px;
  height: 3px;
  border-radius: 30px;
  background-color: rgb(224, 15, 15);
  transition: 0.4s ease-in;
}

#ones {
  transform: rotate(45deg) translateX(5px);
}

#twos {
  transform: rotate(135deg) translateX(-5px);
}

#threes {
  opacity: 0;
  /* transform: rotate(135deg) translate(-10px, 10px); */
}

.media_cover {
  display: none;
  position: absolute;
  transform: translateY(-180px);
  left: 0;
}

.nav_media_hide,
.nav_media_show {
  background-color: rgb(17, 16, 16);
  color: white;
  backdrop-filter: blur(10);
  width: 100vw;
}

.nav_media_hide {
  position: absolute;
  transform: translateY(-180px);
  transition: 1s ease;
  left: 0;
}

.nav_media_show {
  transition: 1s ease;
  position: absolute;
  left: 0;
}

@media screen and (max-width: 1020px) {
  .nav_list {
    display: none;
  }
  .nav_media {
    display: block;
  }
  .media_cover {
    display: block;
  }
  .nav_media_show {
    position: absolute;
    transform: translateY(200px);
    transform: 1s ease;
    z-index: 1;
  }
  .nav_media_hide {
    transition: 1s ease;
    z-index: 1;
  }
  .list_contain li {
    text-align: left;
    list-style: none;
    line-height: 50px;
    margin-left: 30px;
  }

  .navbar_contain img {
    width: 80%;
  }
}


@media screen and (max-width: 500px) {
  .navbar_contain img{
    width: 180px !important;
  }
}
</style>
