<template>
  <div>
    <div :class="model ? 'model open' : 'model'">
      <span class="close" @click="closeModel"
        ><font-awesome-icon icon="fa-solid fa-close"
      /></span>
      <img :src="tempimgSrc" />
    </div>

    <v-card>
    <v-tabs v-model="tab" bg-color="primary">
      <v-tab value="one">Item One</v-tab>
      <v-tab value="two">Item Two</v-tab>
      <v-tab value="three">Item Three</v-tab>
    </v-tabs>

    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="one"> One </v-tabs-window-item>

        <v-tabs-window-item value="two"> Two </v-tabs-window-item>

        <v-tabs-window-item value="three"> Three </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-card>

    <v-row class="gallery-main">
      <v-col cols="12" sm="4" v-for="(item, index) in files" :key="index">
        <img
          v-if="item.value === 'image'"
          :src="item.path"
          style="width: 100%"
          alt="pictures"
          @click="openModel(item.path)"
        />

        <video
          v-if="item.value === 'video'"
          autoplay
          muted
          loop
          controls
          class="video"
        >
          <source :src="item.path" type="video/mp4" />
        </video> 
      </v-col
    ></v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      model: false,
      tempimgSrc: "",
      files: [
        {
          id: 19,
          path: require("@/assets/videos/newcsm.mp4"),
          value: "video",
          name: "fourth",
        },
        {
          id: 1,
          path: require("@/assets/images/gallary/new1.jpg"),
          value: "image",
          name: "first",
        },
        {
          id: 2,
          path: require("@/assets/images/csm12.jpg"),
          value: "image",
          name: "second",
        },
        {
          id: 2,
          path: require("@/assets/images/csm13.jpg"),
          value: "image",
          name: "second",
        },
        {
          id: 2,
          path: require("@/assets/images/csm14.jpg"),
          value: "image",
          name: "second",
        },

        {
          id: 2,
          path: require("@/assets/images/csm19.jpg"),
          value: "image",
          name: "second",
        },
        {
          id: 2,
          path: require("@/assets/images/csm21.jpg"),
          value: "image",
          name: "second",
        },
        {
          id: 2,
          path: require("@/assets/images/csm22.jpg"),
          value: "image",
          name: "second",
        },

        {
          id: 2,
          path: require("@/assets/images/gallary/new3.jpg"),
          value: "image",
          name: "second",
        },
        {
          id: 3,
          path: require("@/assets/images/gallary/new2.jpg"),
          value: "image",
          name: "third",
        },

        {
          id: 12,
          path: require("@/assets/images/gallary/video1.mp4"),
          value: "video",
          name: "fourth",
        },
        {
          id: 6,
          path: require("@/assets/images/gallary/new4.jpg"),
          value: "image",
          name: "fifth",
        },
        // {
        //   id: 10,
        //   path: require("@/assets/images/gallary/car12.jpg"),
        //   value: "image",
        //   name: "ninth",
        // },
        {
          id: 7,
          path: require("@/assets/images/gallary/new5.jpg"),
          value: "image",
          name: "sixth",
        },
        {
          id: 8,
          path: require("@/assets/images/gallary/car11.jpg"),
          value: "image",
          name: "seventh",
        },
        {
          id: 9,
          path: require("@/assets/images/gallary/new6.jpg"),
          value: "image",
          name: "eight",
        },
        {
          id: 9,
          path: require("@/assets/images/gallary/new10.jpg"),
          value: "image",
          name: "eight",
        },
        {
          id: 9,
          path: require("@/assets/images/gallary/new12.jpg"),
          value: "image",
          name: "eight",
        },
        {
          id: 9,
          path: require("@/assets/images/gallary/new14.jpg"),
          value: "image",
          name: "eight",
        },
        {
          id: 9,
          path: require("@/assets/images/gallary/new15.jpg"),
          value: "image",
          name: "eight",
        },
        {
          id: 9,
          path: require("@/assets/images/gallary/new16.jpg"),
          value: "image",
          name: "eight",
        },
      ],
    };
  },
  methods: {
    openModel(imgSrc) {
      this.tempimgSrc = imgSrc;
      this.model = true;
    },
    closeModel() {
      this.model = false;
    },
  },
};
</script>

<style scoped>
.gallery {
  max-width: 1480px;
  margin: 0px auto;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-count: 33%;
  column-width: 33%;
  padding: 0px 12px;
  /* margin-top: 20px; */
}

.gallery-main {
  max-width: 1480px;
  margin: 0px auto;

  column-width: 33%;
  padding: 0px 12px;
  /* margin-top: 20px; */
}

/* OR */

.gallery {
  max-width: 1480px;
  margin: 0px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 0px 20px;
}

.pics {
  transform: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
  transition: 0.5s ease;
}

.pics:hover {
  filter: opacity(0.6);
}

.video {
  width: 75%;
  height: auto;
  max-width: fit-content;
}

@media screen and (max-width: 990px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  /* or */
  .gallery {
    grid-template-columns: 1fr 1fr;
  }

  .video {
    max-width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 490px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-count: 100%;
    column-width: 100%;
  }

  /* or */
  /* you can comment this out */
  .gallery {
    grid-template-columns: 1fr;
  }

  .video {
    max-width: 100%;
    height: 100%;
  }
}

/* model */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  backdrop-filter: blur(2px);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0px 20px;
  margin: 0px auto;
}

.close {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  padding: 7px;
  color: white;
  background-color: #000000db;
  cursor: pointer;
}
</style>
